var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"breadcrumb justify-content-end"},[_c('div',{staticClass:"d-flex  breadcrumb-wrapper pr-1"},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"15"}})],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$t('label_working_time')))])],1)]),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:" mb-1 date-range-col",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_date')))]),_c('div',{staticClass:"date-range-filter"},[_c('custom-date-picker',{staticClass:"date--picker no-label",attrs:{"locale":_vm.$store.state.appConfig.locale,"placeholder":_vm.$t('label_DD-MM-YYYY'),"value":_vm.filterData.executed_date_from},on:{"input":function($event){_vm.filterData.executed_date_from = $event}},scopedSlots:_vm._u([{key:"clear-btn",fn:function(ref){
var vm = ref.vm;
return [_c('feather-icon',{attrs:{"icon":"XIcon","size":"14"}})]}}])}),_c('div',{staticClass:"date-separator"}),_c('custom-date-picker',{staticClass:"date--picker no-label",attrs:{"locale":_vm.$store.state.appConfig.locale,"placeholder":_vm.$t('label_DD-MM-YYYY'),"value":_vm.filterData.executed_date_to},on:{"input":function($event){_vm.filterData.executed_date_to = $event}},scopedSlots:_vm._u([{key:"clear-btn",fn:function(ref){
var vm = ref.vm;
return [_c('feather-icon',{attrs:{"icon":"XIcon","size":"14"}})]}}])})],1)]),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_deal_number')))]),_c('infinity-scroll',{attrs:{"multiple":false,"primary-key":"deal_id","url":"/deal","selectedProp":"deal_id","placeholder":_vm.$t('label_deal_number')},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.deal_number))]}}]),model:{value:(_vm.filterData.deal_id),callback:function ($$v) {_vm.$set(_vm.filterData, "deal_id", $$v)},expression:"filterData.deal_id"}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_user')))]),_c('infinity-scroll',{attrs:{"multiple":false,"primary-key":"id","url":"/user","selectedProp":"id","placeholder":_vm.$t('label_user')},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}]),model:{value:(_vm.filterData.created_user_id),callback:function ($$v) {_vm.$set(_vm.filterData, "created_user_id", $$v)},expression:"filterData.created_user_id"}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_type_of_activity')))]),_c('v-select',{staticClass:"sort--select form-control",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.workingTypes,"label":"label","placeholder":_vm.$t('label_type_of_activity'),"reduce":function (val) { return val.value; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{},[_vm._v(_vm._s(_vm.$t(option.label)))])]}},{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(_vm._s(_vm.$t(option.label)))])]}}]),model:{value:(_vm.filterData.declarative_time),callback:function ($$v) {_vm.$set(_vm.filterData, "declarative_time", $$v)},expression:"filterData.declarative_time"}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2 mt-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-checkbox',{attrs:{"id":"ref-link-filter","value":"1","unchecked-value":"0"},model:{value:(_vm.filterData.show_not_assigned_to_deal),callback:function ($$v) {_vm.$set(_vm.filterData, "show_not_assigned_to_deal", $$v)},expression:"filterData.show_not_assigned_to_deal"}},[_c('label',{attrs:{"for":"ref-link-filter"}},[_vm._v(_vm._s(_vm.$t('label_show_not_assigned_worktime')))])])],1)],1)],1)],1)],1)],1),_c('b-card',{staticClass:"mb-0"},[_c('workingtime-tab',{attrs:{"show-all":true,"extra-filter-data":_vm.filterData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }